import React, { useState, useEffect } from "react";
import { TEInput, TERipple } from "tw-elements-react";
import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useParams } from 'react-router-dom';
import Shimmer from "./Shimmer";
import Cookies from 'js-cookie';

import { Select } from 'antd';
const Option = Select.Option;

export default function RegisterICHPE() {

    const myParameter = Cookies.get('id');

    const { id } = useParams();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [department, setDepartment] = useState("");
    const [institution, setInstitution] = useState("");
    const [designation, setDesignation] = useState("");
    const [phone, setPhone] = useState("");
    const [alternate_email, setAlternateEmail] = useState("");
    const [postal_address, setPostalAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");

      const [isLoadingEvent, setIsLoadingEvent] = useState(true);

      const [departments, setDepartments] = useState([]);
      const [countries, setCountries] = useState([]);
      const [department_id, setDepartmentId] = useState("");
      const [country_id, setCountryId] = useState("");

      const [event_title, setEventTitle] = useState("");
      const [event_description, setEventDescription] = useState("");
      const [event_image, setEventImage] = useState("");

      const [isLoading, setIsLoading] = useState(false);
      const [error, setError] = useState(null);
      const [registrationType, setRegistrationType] = useState('national');
      const [showFieldsEmptyAlert, setShowFieldsEmptyAlert] = useState(false);
      const [showApiSuccessAlert, setShowApiSuccessAlert] = useState(false);
      const [showApiFailureExistsAlert, setShowApiFailureExistsAlert] = useState(false);
      const [showApiFailureAlert, setShowApiFailureAlert] = useState(false);

    const [formErrors, setFormErrors] = useState({
        name: "",
        phone: "",
        email: "",
        institution: "",
        city: "",
        state: "",
        country: "",
    });

    const callErrors = (NAME, PHONE, EMAIL, INSTITUTION, CITY, STATE, COUNTRY) => {
        const errors = {};
        if (NAME === "") {
            errors.full_name = " * Name is Required";
        }
        if (INSTITUTION === "") {
            errors.phone = " * Institution is Required";
        }
        if (PHONE === "") {
            errors.email = " * Phone is Required";
        }
        if (EMAIL === "") {
            errors.registration_number = " * Email is Required";
        }
        if (CITY === "") {
            errors.personal_email = " * City is Required";
        }
        if (STATE === "") {
            errors.cnic_passport = " * State is Required";
        }
        if (COUNTRY === "") {
            errors.nationality = " * Country is Required";
        }

        setFormErrors(errors);
    };

    const handleFocus = (e) => {
        const { name } = e.target;

        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [name]: "",
        }));
    };


      const handleRegistration = async () => {
        if (registrationType === 'national') {
            if (name && phone && email && institution && city && state && country) {
                callErrors(name, phone, email, institution, city, state, country);
            }
        } else {
            callErrors(name, phone, email, institution, city, state, country);
        }
      };

    //   const getEventById = async (id) => {
    //     const requestOptions = {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     };

    //     try {
    //       const response = await fetch(`https://services.uol.edu.pk/api/get-event/${id}`, requestOptions);
    //       if (response.ok) {
    //         const data = await response.json();
    //         const event = data.event;
    //         setEventTitle(event.event_title);
    //         setEventDescription(event.event_description);
    //         setEventImage(event.event_image);

    //         console.log('Event Image: ', event.event_image);
    //       } else {
    //         throw new Error('Failed to fetch event data');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     } finally {
    //       setIsLoadingEvent(false);
    //     }
    //   }

      const getDepartments = async (d) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        try {
          const response = await fetch(`https://services.uol.edu.pk/api/get-departments`, requestOptions);
          if (response.ok) {
            const data = await response.json();
            const departments = data.departments;
            setDepartments(departments);

            console.log('Departments: ', departments);
          } else {
            throw new Error('Failed to fetch event data');
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingEvent(false);
        }
      }

      const getCountries = async (d) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        try {
          const response = await fetch(`https://services.uol.edu.pk/api/get-all-country`, requestOptions);
          if (response.ok) {
            const data = await response.json();
            const countries = data.Country;
            setCountries(countries);

            console.log('Countries: ', countries);
          } else {
            throw new Error('Failed to fetch event data');
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingEvent(false);
        }
      }
    //   const registerGuest = async () => {
    //     setIsLoading(true);
    //     setError(null);

    //     const data = {
    //       email,
    //       full_name,
    //       phone,
    //       event_id: id,
    //       registrationType,
    //       registration_number,
    //       area_of_study,
    //       topic_of_research,
    //       type_of_participation,
    //       personal_email,
    //       department_id,
    //       cnic_passport,
    //       nationality
    //     };

    //     try {
    //       const requestOptions = {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(data),
    //       };

    //       const response = await fetch('https://services.uol.edu.pk/api/guest-registration', requestOptions);

    //       if (!response.ok) {
    //         if (response.status === 409) {
    //           setShowApiFailureExistsAlert(true);
    //           setTimeout(() => {
    //             setShowApiFailureExistsAlert(false);
    //           }, 3000);
    //         } else {
    //           setShowApiFailureAlert(true);
    //           setTimeout(() => {
    //             setShowApiFailureAlert(false);
    //           }, 3000);
    //         }
    //       } else {
    //         setShowApiSuccessAlert(true);
    //         setTimeout(() => {
    //           setShowApiSuccessAlert(false);
    //         }, 3000);

    //         setEmail("");
    //         setFullName("");
    //         setPhone("");
    //         setRegistrationNumber("");
    //         setAreaOfStudy("");
    //         setTopicOfResearch("");
    //         setPersonalEmail("");
    //         setCnicPassport("");
    //         setNationality("");
    //         setFormErrors({
    //           full_name: "",
    //           phone: "",
    //           email: "",
    //           registration_number: "",
    //           personal_email: "",
    //           cnic_passport: "",
    //           nationality: "",
    //         });
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     } finally {
    //       setIsLoading(false);
    //     }
    //   };

      const handleChange = (event, newRegistrationType) => {
        if (newRegistrationType !== null) {
          setRegistrationType(newRegistrationType);
        }
      };

      function handleDepartmentChange(value) {
        console.log(`selected ${value}`);
        setDepartmentId(value);
      }

      function handleCountryChange(value) {
        console.log(`selected ${value}`);
        // setNationality(value);
      }

      useEffect(() => {
      }, []);


    return (
        <div className="w-full mx-auto" style={{ maxWidth: '600px' }}>
            <div className="w-full h-auto px-0 sm:px-10">
                <div className="max-w-xl w-full mx-auto">
                    <div className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
                        <div className="lg:flex lg:flex-wrap">
                            <div className="px-2 md:px-0 lg:w-full">
                                <div className="md:mx-6 md:p-12">
                                    <div className="text-center pt-5">
                                        <img className="mx-auto w-48" src="/images/UOL_logo.png" alt="logo" />
                                        <h4 className="mt-1 pb-1 text-xl font-semibold">
                                            {/* {event_title} */}
                                            Demo Title
                                        </h4>
                                        <img
                                            className="p-5"
                                            //   src={`https://services.uol.edu.pk/attachments/${event_image}`}
                                            alt="conference"
                                        />
                                        <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: event_description }} />


                                    </div>
                                    <div className="flex justify-center w-1/2 px-2 mb-5">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={registrationType}
                                            exclusive
                                            onChange={handleChange}
                                            aria-label="Platform"
                                            size="small"
                                        >
                                            <ToggleButton className="text-sm px-2 py-1" size="small" value="national">National Participant</ToggleButton>
                                            <ToggleButton className="text-sm px-2 py-1" size="small" value="international">International Participant</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    {registrationType === 'national' ? (
                                        <div className="mx-auto max-w-md">

                                            <div className="input-container mb-4">
                                                <label htmlFor="fullName" className="fixed-label">Name *</label>
                                                <TEInput
                                                    type="text"
                                                    id="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                    onFocus={handleFocus}
                                                />
                                                <div style={{ color: "red" }}>{formErrors.name}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="email" className="fixed-label">Email *</label>
                                                <TEInput
                                                    type="text"
                                                    id="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                    onFocus={handleFocus}
                                                />
                                                <div style={{ color: "red" }}>{formErrors.email}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="phoneNumber" className="fixed-label">Phone Number *</label>
                                                <TEInput
                                                    type="text"
                                                    id="phoneNumber"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    required
                                                />
                                                <div style={{ color: "red" }}>{formErrors.phone}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="department" className="fixed-label">Department</label>
                                                <TEInput
                                                    type="text"
                                                    id="department"
                                                    value={department}
                                                    onChange={(e) => setDepartment(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="institution" className="fixed-label">Institution *</label>
                                                <TEInput
                                                    type="text"
                                                    id="institution"
                                                    value={institution}
                                                    onChange={(e) => setInstitution(e.target.value)}
                                                    required
                                                />
                                                <div style={{ color: "red" }}>{formErrors.institution}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="designation" className="fixed-label">Designation</label>
                                                <TEInput
                                                    type="text"
                                                    id="designation"
                                                    value={designation}
                                                    onChange={(e) => setDesignation(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="alternateEmail" className="fixed-label">Alternate Email</label>
                                                <TEInput
                                                    type="text"
                                                    id="alternateEmail"
                                                    value={alternate_email}
                                                    onChange={(e) => setAlternateEmail(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="postalAddress" className="fixed-label">Postal Address</label>
                                                <TEInput
                                                    type="text"
                                                    id="postalAddress"
                                                    value={postal_address}
                                                    onChange={(e) => setPostalAddress(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    ) : registrationType === 'international' ? (
                                        <div className="mx-auto max-w-md">

                                            <div className="input-container mb-4">
                                                <label htmlFor="fullName" className="fixed-label">Name *</label>
                                                <TEInput
                                                    type="text"
                                                    id="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                    onFocus={handleFocus}
                                                />
                                                <div style={{ color: "red" }}>{formErrors.name}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="personalEmail" className="fixed-label">Email *</label>
                                                <TEInput
                                                    type="text"
                                                    id="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                    onFocus={handleFocus}
                                                />
                                                <div style={{ color: "red" }}>{formErrors.email}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="phoneNumber" className="fixed-label">Phone Number *</label>
                                                <TEInput
                                                    type="text"
                                                    id="phoneNumber"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    required
                                                />
                                                <div style={{ color: "red" }}>{formErrors.phone}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="department" className="fixed-label">Department</label>
                                                <TEInput
                                                    type="text"
                                                    id="department"
                                                    value={department}
                                                    onChange={(e) => setDepartment(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="institution" className="fixed-label">Institution *</label>
                                                <TEInput
                                                    type="text"
                                                    id="institution"
                                                    value={institution}
                                                    onChange={(e) => setInstitution(e.target.value)}
                                                    required
                                                />
                                                <div style={{ color: "red" }}>{formErrors.institution}</div>
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="designation" className="fixed-label">Designation</label>
                                                <TEInput
                                                    type="text"
                                                    id="designation"
                                                    value={designation}
                                                    onChange={(e) => setDesignation(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="alternateEmail" className="fixed-label">Alternate Email</label>
                                                <TEInput
                                                    type="text"
                                                    id="alternateEmail"
                                                    value={alternate_email}
                                                    onChange={(e) => setAlternateEmail(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="input-container mb-4">
                                                <label htmlFor="postalAddress" className="fixed-label">Postal Address</label>
                                                <TEInput
                                                    type="text"
                                                    id="postalAddress"
                                                    value={postal_address}
                                                    onChange={(e) => setPostalAddress(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {error && <p className="text-red-500">{error}</p>}
                                    <div className="mx-auto max-w-md mb-12 pb-5 pt-5 text-center">
                                        <TERipple rippleColor="light" className="w-full">
                                            <button
                                                className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                                                type="button"
                                                style={{
                                                    background: "linear-gradient(to right, #24773a, #30a04c, #41c662, #4ce071)",
                                                }}
                                                onClick={handleRegistration}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? "Submitting..." : "Submit"}
                                            </button>
                                        </TERipple>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showApiSuccessAlert && (
                <div className="fixed bottom-10 inset-x-0 px-4 pb-10 sm:inset-0 sm:flex sm:items-center sm:justify-center">
                    <Alert severity="success" onClose={() => setShowApiSuccessAlert(false)}>
                        Registration Successful! Kindly check your Email.
                    </Alert>
                </div>
            )}
            {showApiFailureExistsAlert && (
                <div className="fixed bottom-10 inset-x-0 px-4 pb-10 sm:inset-0 sm:flex sm:items-center sm:justify-center">
                    <Alert severity="error" onClose={() => setShowApiFailureExistsAlert(false)}>
                        User Already Registered!
                    </Alert>
                </div>
            )}
            {showApiFailureAlert && (
                <div className="fixed bottom-10 inset-x-0 px-4 pb-10 sm:inset-0 sm:flex sm:items-center sm:justify-center">
                    <Alert severity="error" onClose={() => setShowApiFailureAlert(false)}>
                        Failed to register. Please try again later!
                    </Alert>
                </div>
            )}
            {showFieldsEmptyAlert && (
                <div className="fixed bottom-10 inset-x-0 px-4 pb-10 sm:inset-0 sm:flex sm:items-center sm:justify-center">
                    <Alert severity="warning" onClose={() => setShowFieldsEmptyAlert(false)}>
                        Fill out the fields!
                    </Alert>
                </div>
            )}
        </div>
    );
}
