import { React } from 'react'
import { Stack } from '@mui/material';
function NotFound() {

    const handleEventCall = () => {
        window.location.href = `/`;
    };

    return (
        <div>
            <img className="mx-auto w-48" src="/images/UOL_logo.png" alt="logo" />
            <img className="mx-auto w-48 h-100" src="/images/not_found.png" style={{ width: "800px", height: "100", paddingTop: "5px" }} alt="logo" />
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <button
                    className="mb-3 inline-block w-48 justify-center rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                    type="button"
                    style={{
                        background: "linear-gradient(to right, #24773a, #30a04c, #41c662, #4ce071)",
                        marginTop: 'auto',
                        marginBottom: '20px'
                    }}
                    onClick={handleEventCall}
                >
                    Back To Home
                </button>
            </Stack>
        </div>
    );
}

export default NotFound;
